<template>

	<div class="Cgv">

    <div class="Cgv--container">

      <div class="Cgv--container--header">
        <p v-if="$route.params.language == 'fr'">Conditions générales de vente</p>
        <p v-if="$route.params.language == 'en'">General terms and conditions</p>
      </div>

      <div class="Cgv--container--content" :class="'content' + this.whatComputConfig">
        
        <div class="Cgv--container--content--mentions" 
        :class="'mentions' + this.whatComputConfig" v-html="state.mentions">
        </div>

        <div class="Cgv--container--content--cgv" 
        :class="'cgv' + this.whatComputConfig" v-html="state.cgv">
        </div>

      </div>

    </div>

	</div>
  
</template>

<script>
import clientConfigs from '@/mixins/clientConfigs.js'

export default {
  name: 'Cgv',
  mixins: [clientConfigs],
  data: function () {
   return {
     state: ''
   }
  },
  components: {


  },
  async created() {
    let response = await this.$api.getPage('cgv')
    this.state = response

    // console.log(response)
  }


}
</script>




<style lang="scss">
@import '@/css/variables';
@import '@/css/snipcart';
@import '@/css/transition';

.Cgv{
  z-index: 2;
  position: absolute;

  width: 100%;
  min-height: 100%;

  background-color: white;

  &--container{

    width: 100%;

    &--header{

      position: fixed;

      width: 100%;

      height: var(--shop-menu-header-height);

      font-size: var(--shop-menu-header-font);
      background-color: black;
      color: white;
      z-index: 202;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & p {
        margin-top: 0.1em;

      }
    }

    &--content{
      
      position: absolute;
      margin-top: var(--shop-menu-header-height);

      background-color: white;
      display: flex;
      justify-content: space-between;

      &.content-mobile{
        flex-direction: column;
      }

      &--mentions{

        width: calc((100% - var(--esp-med)) / 7 * 3);
        font-size: var(--shop-font-labeur-title);
        padding-top: var(--esp-med);
        padding-left: var(--esp-med);
        padding-right: var(--esp-med);

        &.mentions-mobile{
          width: calc(100% - (2 * var(--esp-med)) );
        }
      }

      &--cgv{
        width: calc((100% - var(--esp-med)) / 7 * 3);
        font-family: 'vd-reg';
        font-size: var(--shop-font-labeur);

        line-height: 1.2;

        padding-top: var(--esp-med);
        padding-right: var(--esp-med);
        padding-left: var(--esp-med);

        &.cgv-mobile{
          padding-top: var(--esp-big);
          width: calc(100% - (2 * var(--esp-med)) );

        }

        & p {
          padding-bottom: var(--esp-small);
        }

        // & p:last-child{
        //   padding-bottom: 0vw;
        // }
      }


    }

  }


}
</style>